
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import imageResizeProcessor from '@/modules/Profile/services/imageResizeProcessor';
import env from '@/environment';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { SelectOptionsType } from '@/components/select/types';
import { FileType } from '@/components/fileArea/types';
import {
    COUNTRY_SELECT_OPTIONS, TOOLTIP_BIRTH_DATE_TOOLTIP, TOOLTIP_FIRST_NAME_TOOLTIP,
    TOOLTIP_LAST_NAME_TOOLTIP, TOOLTIP_NAME_OF_ACCOUNT_TOOLTIP,
} from '@/modules/constants';
import { resolveBankingDetails } from '@/modules/labels';
import { NATIONAL_NUMBER_MESSAGE, BIRTHDATE_MESSAGE } from '@/utils/messages/formValidation';
import register from '@/modules/Register';
import countries from '@/modules/Countries';
import registerProfilePicture from '@/modules/Register/registerProfilePicture';
import UploadDialogContent from '@/projectComponents/uploadDialog/index.vue';
import Camera from '@/projectComponents/camera/index.vue';
import authenticator from '@/modules/Auth/services/authenticator';
import { AssetType, RegisterAmbassadorType, UploadProfilePictureType } from '@/api/graphQL/graphNodes/types';
import { BankingDetailsResolverType, LabelType } from '@/modules/types';
import FormErrorTooltip from '@/projectComponents/formErrorTooltip/index.vue';
import { LEXIS_NEXIS_STATUS, REGISTRATION_STEPS } from '@/modules/Register/constants';
import { TEXT_FIELD_DISPLAY_TYPE } from '@/components/textField/constants';
import { DATE_PICKER_TYPES } from '@/components/datePicker/constants';
import FileViewer from '@/projectComponents/fileViewer/index.vue';
import RegistrationSteps from '@/projectComponents/registrationSteps/index.vue';
import { RegistrationStepsOptionsType } from '@/projectComponents/registrationSteps/types';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import { CountriesType } from '@/modules/Event/types';
import AddressSelect from '@/projectComponents/addressSelect/index.vue';
import { FIELD_PHONE_CODE_FORMAT } from '@/utils/messages/helperTexts';
import BusinessDetails from './BusinessDetails/index.vue';

@Component({
    components: {
        UploadDialogContent,
        Camera,
        FormErrorTooltip,
        FileViewer,
        FormFieldTooltip,
        RegistrationSteps,
        AddressSelect,
        BusinessDetails,
    },
})
export default class RegisterContent extends Vue {
    @Get(register) private formData!: RegisterAmbassadorType;

    @Get(register) private birthDate!: string;

    @Get(register) private labels!: LabelType;

    @Get(register) private termsAndConditions!: AssetType|null;

    @Get(register) private formErrors!: ErrorType;

    @Get(register) private customerNumber!: string|null;

    @Get(register) private userId!: string|null;

    @Get(register) private imageDialogOpen!: boolean;

    @Get(register) private termsDialogOpen!: boolean;

    @Get(register) private loading!: boolean;

    @Get(register) private loadingInBackground!: boolean;

    @Get(registerProfilePicture) private image!: string;

    @Get(register) private profilePicture!: UploadProfilePictureType;

    @Get(registerProfilePicture, 'loading') private uploadLoading!: boolean;

    @Get(registerProfilePicture) private isCameraLoading!: boolean;

    @Get(register) private profilePicUpload!: FileType|null;

    @Get(registerProfilePicture) private isPhotoTaken!: boolean;

    @Get(registerProfilePicture) private isShotPhoto!: boolean;

    @Get(registerProfilePicture) private displayWidth!: number;

    @Get(registerProfilePicture) private displayHeight!: number;

    @Get(registerProfilePicture) private cameraWidth!: number;

    @Get(registerProfilePicture) private cameraHeight!: number;

    @Get(registerProfilePicture) private cameraNotPermitted!: boolean;

    @Get(register) private cameraDialogOpen!: boolean;

    @Get(register) private lexisNexisStatus!: string|null;

    @Get(register) private displayTooltip!: boolean;

    @Get(countries) private regions!: CountriesType[];

    private termsAndConditionEnable = false;

    private isDocumentLoading = true;

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    get bankingDetails(): BankingDetailsResolverType {
        return resolveBankingDetails(this.formData.region);
    }

    get ibanType(): string {
        if (this.bankingDetails.displayStyle === 'UK') {
            return TEXT_FIELD_DISPLAY_TYPE.bankAccountNumber;
        }

        return TEXT_FIELD_DISPLAY_TYPE.iban;
    }

    get sortCodeType(): string|null {
        if (this.bankingDetails.displayStyle === 'UK') {
            return TEXT_FIELD_DISPLAY_TYPE.sortCode;
        }

        return TEXT_FIELD_DISPLAY_TYPE.swift;
    }

    get registrationSteps(): RegistrationStepsOptionsType[] {
        return REGISTRATION_STEPS;
    }

    get nameOfAccountTooltip(): string {
        return TOOLTIP_NAME_OF_ACCOUNT_TOOLTIP;
    }

    get firstNameTooltip(): string {
        return TOOLTIP_FIRST_NAME_TOOLTIP;
    }

    get lastNameTooltip(): string {
        return TOOLTIP_LAST_NAME_TOOLTIP;
    }

    get birthDateTooltip(): string {
        return TOOLTIP_BIRTH_DATE_TOOLTIP;
    }

    get countriesOptions(): SelectOptionsType[] {
        return this.regions.map((item) => ({
            name: item.longLabel,
            value: item.shortLabel,
        }));
    }

    get isGB(): boolean {
        return this.formData.region === COUNTRY_SELECT_OPTIONS.gb;
    }

    get gbCountry(): string {
        return COUNTRY_SELECT_OPTIONS.gb;
    }

    get logoUrl(): string {
        return `${env.BASE_URL}img/horizontal-be-curious-logo.jpeg`;
    }

    get imgUrl(): string {
        return `${this.awsProfile ? '' : this.serverUrl}${this.link}`;
    }

    get serverUrl(): string {
        return env.VUE_APP_SERVER_URL;
    }

    get link(): string {
        return this.profilePicture ? this.profilePicture.link : '';
    }

    get awsProfile(): boolean {
        if (this.profilePicture !== null) {
            const provider = this.profilePicture.context.find((item) => item.metric === 'provider');
            if (provider && provider.value === 'aws_profile') {
                return true;
            }
        }
        return false;
    }

    get passwordRequired(): boolean {
        return !this.userId;
    }

    get logged(): boolean {
        const { logged } = this.$route.query;
        return logged === 'true';
    }

    get title(): string {
        return this.logged ? 'We need some additional details from You' : 'Join the community';
    }

    get birthDateType(): string {
        return TEXT_FIELD_DISPLAY_TYPE.birthDate;
    }

    get phoneCodeOptions(): SelectOptionsType[] {
        const res: SelectOptionsType[] = [];
        this.regions.forEach((region) => {
            const { phoneCode } = region;
            if (phoneCode !== null && !res.find((item) => item.name === phoneCode)) {
                res.push({
                    name: phoneCode!,
                    value: phoneCode!,
                });
            }
        });

        return res.sort((a: any, b: any) => a.value! - b.value!);
    }

    get helperPhoneNumber(): string {
        return FIELD_PHONE_CODE_FORMAT;
    }

    handleTermsDialog() {
        register.toggleTermsDialogOpen();
    }

    handleTermsChecked() {
        register.setTermsCheckbox(true);
        register.toggleTermsDialogOpen();
    }

    @Watch('formData.region')
    handleCountryChange(val: string) {
        register.setLabels(resolveBankingDetails(val).labels);
    }

    getHelperText(val: string) {
        if (val === 'birthDate') {
            return BIRTHDATE_MESSAGE;
        }
        return NATIONAL_NUMBER_MESSAGE;
    }

    handleValue(value, key) {
        if (key === 'birthDate') {
            register.setBirthDate(value);
            register.removeFormError('birthDate');
        } else {
            const res = ObjectProcessor.setPropertyByValue(key, value, this.formData);
            if (key === 'password') {
                register.removeFormError('plainPassword');
            } else {
                register.removeFormError(key);
            }
            register.setFormData(res);
        }
    }

    handleLoaded() {
        setTimeout(this.setupReadDocument, 1000);
    }

    setupReadDocument() {
        this.isDocumentLoading = false;

        const el = this.$refs.termsAndConditionsPreviewContainer as Element;
        if (el.clientHeight > 0 && el.scrollHeight > el.clientHeight) {
            el.addEventListener('scroll', this.handleDocumentEnd);
        } else {
            this.termsAndConditionEnable = true;
        }
    }

    handleDocumentEnd(event) {
        const el = this.$refs.termsAndConditionsPreviewContainer as Element;

        const {
            scrollHeight, clientHeight, scrollTop,
        } = event.target;

        if (Math.abs(scrollHeight - clientHeight - scrollTop) < 20) {
            this.termsAndConditionEnable = true;
            el.removeEventListener('scroll', this.handleDocumentEnd);
        }
    }

    async handleRegister() {
        try {
            await register.registerAmbassador({
                token: this.$route.query.token.toString(),
                passwordCheck: this.passwordRequired,
            });
            if (Object.keys(this.formErrors).length === 0
             && this.lexisNexisStatus !== LEXIS_NEXIS_STATUS.refer) {
                if (!this.logged) {
                    authenticator.logout(false);
                    authenticator.startLogin();
                } else {
                    this.$router.push({ name: 'dashboard' });
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    // handleImageUploadModalOpen() {
    //     register.setImageDialogOpen(true);
    // }

    // handleCameraModalOpen() {
    //     register.setCameraDialogOpen(true);
    // }

    beforeDestroy() {
        register.setDisplayTooltip(false);
    }

    handleImageUploadModalClose() {
        register.setImageDialogOpen(false);
        registerProfilePicture.clearImageData();
    }

    handleReupload() {
        registerProfilePicture.clearImageData();
    }

    setDimensions(val: {width: number, height: number}) {
        registerProfilePicture.setDisplayWidth(val.width);
        registerProfilePicture.setDisplayHeight(val.height);
    }

    async handleProfileUpload() {
        // @ts-ignore
        const croppedImg = this.$refs.upload.$refs.uploadContent.$refs.cropArea.$refs.cropper
            .getCroppedCanvas().toDataURL();
        registerProfilePicture.uploadProfilePicture({
            img: croppedImg,
            token: this.$route.query.token.toString(),
        });
    }

    async onFileAdd(files: FileType[]) {
        const file64 = await imageResizeProcessor(files[0].file);
        registerProfilePicture.setImage(file64);
    }

    handleCameraModalClose() {
        register.setCameraDialogOpen(false);
    }

    async handleCameraUpload() {
        // @ts-ignore
        const croppedImg = this.$refs.camera.getCroppedImg();
        await registerProfilePicture.uploadProfilePicture({
            img: croppedImg,
            token: this.$route.query.token.toString(),
        });
        this.handleCameraModalClose();
    }

    async startCamera() {
        await registerProfilePicture.clearImageData();
        this.createCameraElement();
    }

    createCameraElement() {
        registerProfilePicture.setIsCameraLoading(true);
        registerProfilePicture.setCameraNotPermitted(false);

        const params = {
            audio: false,
            video: true,
        };

        // @ts-ignore
        const constraints = params;

        // @ts-ignore
        window.constraints = params;
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                const streamSettings = stream.getVideoTracks()[0].getSettings();
                const { width, height } = streamSettings;

                if (width && height) {
                    registerProfilePicture.setCameraWidth(width);
                    registerProfilePicture.setCameraHeight(height);

                    // @ts-ignore
                    this.$refs.camera.setFittingResolution(width, height);
                }

                registerProfilePicture.setIsCameraLoading(false);
                // @ts-ignore
                this.$refs.camera.$refs.camera.srcObject = stream;
            })
            .catch(() => {
                registerProfilePicture.setCameraNotPermitted(true);
            })
            .finally(() => {
                registerProfilePicture.setIsCameraLoading(false);
            });
    }

    takePhoto() {
        if (!this.isPhotoTaken) {
            registerProfilePicture.setIsShotPhoto(true);

            const FLASH_TIMEOUT = 50;

            setTimeout(() => {
                registerProfilePicture.setIsShotPhoto(false);
            }, FLASH_TIMEOUT);
        }
        registerProfilePicture.setIsPhotoTaken(!this.isPhotoTaken);

        // @ts-ignore
        const getPhoto = this.$refs.camera.getPhotoRef(this.isPhotoTaken);
        registerProfilePicture.setImage(getPhoto);
    }

    @Watch('cameraDialogOpen')
    cameraDialogOpenChange() {
        if (this.cameraDialogOpen) {
            this.startCamera();
        }
    }
}
